import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { GlobalSearch } from '../index';
import { defaultTitle } from '../constants';
import { FavoriteContext } from '../Favorite/favoriteContext';
import { FAVORITE_MODULES_LIST_ID, FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';
import TopProviders from '../Insights/topProviders';
import TopModules from '../Insights/topModules';

export const Dashboard = () => {
    useEffect(() => {
        document.title = defaultTitle;
    }, []);

    const { getFavoriteItems } = useContext(FavoriteContext);
    const favoriteProviderIds = getFavoriteItems(FAVORITE_PROVIDERS_LIST_ID);
    const favoriteModuleIds = getFavoriteItems(FAVORITE_MODULES_LIST_ID);

    return (
        <Row>
            <Col lg={12} className="landing-block">
                <Row className="justify-content-center">
                    {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <Col lg={12} className="vh-5 vh-10-sm">
                            &nbsp;
                        </Col>
                        <Col lg={12} className="text-center text-block">
                            <h1>All of the registries, all of the insights.</h1>

                            <h5 className="py-4 text-muted">
                                Library.tf is built and designed to not just provide you with all of the registry
                                information but to provide all of the insights you
                                need to make decisions. Quickly find modules or providers that are supported and
                                maintained and not full of bugs.
                            </h5>

                            <Row className="justify-content-center">
                                <Col lg={8} className="text-start global-search global-search-start">
                                    <GlobalSearch autoFocus />
                                </Col>
                            </Row>
                        </Col>
                    </IconContext.Provider>
                </Row>
                {(favoriteProviderIds.length > 0 || favoriteModuleIds.length > 0) && (
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <Row className="mt-2 justify-content-center">
                            { favoriteProviderIds.length > 0  && (
                                <Col lg={5} xl={5}>
                                    <TopProviders
                                        short
                                        showSeeAll
                                        type="favorite"
                                        limit={5}
                                        providerIds={favoriteProviderIds}
                                    />
                                </Col>
                            )}
                            { favoriteModuleIds.length > 0  && (
                                <Col lg={5} xl={5}>
                                    <TopModules
                                        short
                                        showSeeAll
                                        type="favorite"
                                        limit={5}
                                        moduleIds={favoriteModuleIds}
                                    />
                                </Col>
                            )}
                        </Row>
                    </IconContext.Provider>
                )}
            </Col>
        </Row>
    );
};

export default Dashboard;
