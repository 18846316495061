import React, { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';

import { defaultTitle } from "./constants";

const OpenTofuAndTerraform = () => (
    <>
        <span className="text-nowrap mx-1">
            <Image src="/images/icons/opentofu.svg" height="16" className="me-1 mb-1" alt="OpenTofu"/>
            OpenTofu
        </span>
        and
        <span className="text-nowrap mx-1">
            <Image src="/images/icons/terraform.svg" height="16" className="me-1 mb-1" alt="Terraform" />
            Terraform
        </span>
    </>
);

export const AboutPage = () => {
    useEffect(() => {
        document.title = `About Page | ${defaultTitle}`;
    }, []);

    return (
        <Row>
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <IconContext.Provider value={{ className: 'react-icons' }}>
                <Col lg={12} className="landing-block">
                    <Row className="justify-content-center">
                        <Col lg={12} className="vh-5">
                            &nbsp;
                        </Col>
                        <Col lg={8} xxl={6}>
                            <h1 className="mb-4">
                                About Us
                            </h1>
                            <p>
                                Welcome to <strong>Library.tf</strong> – a comprehensive registry of registries tailored for <OpenTofuAndTerraform />
                                enthusiasts! Developed and maintained by the team at{' '}
                                <Link to="https://www.scalr.com/" className="text-decoration-none">
                                    Scalr
                                </Link>
                                , our site is designed with a singular purpose: to simplify and make it easier for our users navigating the expansive landscape of
                                providers and modules. The ever-growing ecosystem of providers and modules demands a centralized hub that not only catalogs these
                                assets but also offers valuable insights to streamline your search.
                            </p>
                            <h2 className="my-4">Key Features:</h2>
                            <ul>
                                <li className="py-2">
                                    <strong className="text-primary-emphasis">Registry of Registries:</strong> <strong>Library.tf</strong> is your gateway to a curated
                                    collection of registries. No more scouring the internet — find everything you need in one centralized location.
                                </li>
                                <li className="py-2">
                                    <strong className="text-primary-emphasis">Comprehensive Search:</strong> Our intuitive search functionality ensures you can pinpoint
                                    the providers and modules that match your specific requirements effortlessly.
                                </li>
                                <li className="py-2">
                                    <strong className="text-primary-emphasis">Rich Insights:</strong> We go beyond a simple directory. <strong>Library.tf</strong>{' '}
                                    provides insightful data and analytics, about the modules and providers, by joining registry and repository data all in one place.
                                    This empowers users to make informed decisions about the providers and modules they choose.
                                </li>
                            </ul>
                            <h2 className="my-4">Why Library.tf?</h2>
                            <p>
                                <strong>Library.tf</strong> is not just a registry — it&apos;s a collection of registries built for the community, dedicated to making
                                your journey with <OpenTofuAndTerraform /> more efficient and enjoyable. Whether you are a seasoned developer or just starting, our
                                goal is to simplify the discovery process, allowing you to focus on what you do best — building amazing projects.
                            </p>
                            <p>
                                Join us using <strong>Library.tf</strong> and let&apos;s navigate the world of providers and modules together with insights. If you
                                have any ideas for improvements, let us know{' '}
                                <Link to="https://forms.gle/1eZhCvAQBJtVQ2UA8" className="text-decoration-none" target="_blank" rel="noopener">
                                    here
                                </Link>
                                .
                            </p>
                        </Col>
                    </Row>
                </Col>
            </IconContext.Provider>
        </Row>
    );
};

export default AboutPage;
